'use strict';
Dafi.View.SubscribeLine = {
    options: {
        lineRestoreTime: 86400000 * 2, //48 hours
        localStorageItemName: "SubscribeLineClosingTime",
        delayBeforeShow: 30000 //30 seconds
    },
    timer: 0,
    init: function () {
        this.options.delayBeforeShowStorageItemName = this.options.localStorageItemName + '.delayBeforeShow';
        localStorage.setItem(this.options.delayBeforeShowStorageItemName, this.getDelayBeforeShow());

        this.$el = $('[data-el="subscribe-line"]');
        this.$closeTrigger = $('[data-el="close-subscribe-line"]');

        this.listen();
    },
    show: function () {
        if (this.isNeedShow()) {
            this.$el.addClass('active');

            if (this.timeToShow()) {
                localStorage.removeItem(this.options.localStorageItemName);
            }
        }
    },
    hide: function () {
        this.$el.removeClass('active');

        if (Dafi.Helper.User.supportLocalStorage()) {
            localStorage.setItem(this.options.localStorageItemName, +new Date);
        }
    },
    isNeedShow: function () {
        return !Dafi.Helper.User.supportLocalStorage() || this.timeToShow();
    },
    timeToShow: function () {
        var isSupportStorage = Dafi.Helper.User.supportLocalStorage(),
            closingTime = isSupportStorage ? localStorage.getItem(this.options.localStorageItemName) : 0
            ;

        return (+new Date - closingTime) > this.options.lineRestoreTime;
    },
    listen: function () {
        var context = this;

        this.$closeTrigger.click(function (event) {
            context.hide.call(context);
            event.preventDefault();
        });

        this.timer = setInterval(function () {
            if ((+new Date - context.getDelayBeforeShow()) > context.options.delayBeforeShow) {
                clearInterval(context.timer);
                context.show();
            }
        }, 1000);
    },
    getDelayBeforeShow: function () {
        return parseInt(localStorage.getItem(this.options.delayBeforeShowStorageItemName)) || (+new Date);
    }
};