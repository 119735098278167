'use strict';
Dafi.View.ChangeFontSize = {
    isInited: false,
    selector: '[data-el="change-font-size"]',
    property: 'font-2x',
    init: function () {
        this.initOne();
    },
    initOne: function () {
        if (this.isInited) {
            return;
        }

        this.isInited = true;

        if (this.isFont2x()) {
            this.toggleObject();
        }

        this.listen();
    },
    listen: function () {
        var context = this;

        $(document).on('click', this.selector, function (event) {
            context.toggleObject();

            localStorage.setItem(
                context.property,
                !context.isFont2x() ? 'yeap' : 'nope'
            );

            event.preventDefault();
        });
    },
    isFont2x: function () {
        return localStorage.getItem(this.property) === 'yeap';
    },
    toggleObject: function () {
        Dafi.View.Body.$el.toggleClass(this.property);
    }
};