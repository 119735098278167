'use strict';
Dafi.View.PagePreloader = {
    isInited: false,
    init: function () {
        this.initOne();
    },
    initOne: function () {
        if (this.isInited) {
            return;
        }

        this.isInited = true;

        var template;
        template = tmpl('template-page-preloader');

        Dafi.View.pjaxContainer.$el.append(template);

        this.$el = $('[data-el="page-preloader"]');
    },
    toggle: function() {
        this.$el.toggleClass('active');
    },
    show: function () {
        this.$el.addClass('active');
    },
    hide: function () {
        this.$el.removeClass('active');
    }
};