(function ($) {
    var pluginName = 'dynamicPageContent';

    var DynamicPageContent = function (element, options) {
        var self = this,
            location = window.location.pathname,
            args = {};

        if (typeof element.data('location') != 'undefined') {
            location = element.data('location');
        }

        if (typeof element.data('args') != 'undefined') {
            args = element.data('args');
        }

        options = $.extend({
            url: location,
            args: args,
            page: 1,
            callback: null,
            bottomHeightRatio: 1,
            isScroll: true
        }, options[0]);

        var w = $(window),
            d = $(document),
            pxToBottom = w.height() * options.bottomHeightRatio,
            page = options.page;

        var init = function () {
            setScrollListener();
        }

        this.load = function (callback) {
            var data = $.extend(options.args, {
                url: options.url,
                page: page + 1
            });

            sajax('dynamic_page_content', 'getData', data, function (response) {
                if (response.length && response.substr(0, 1) === '{') {
                    response = JSON.parse(response);
                } else {
                    response = null;
                }

                if (response !== null && response.items.length) {
                    element.append(response.items.join(response.delimiter));
                    ++page;

                    if (typeof options.callback === 'function') {
                        options.callback(response)
                    }

                    init();
                }

                if (typeof callback === 'function') {
                    callback(response)
                }
            });
        }

        var onScroll = function () {
            if(w.scrollTop() + w.height() > d.height() - pxToBottom) {
                w.off('.' + pluginName);
                self.load();
            }
        }

        var setScrollListener = function () {
            if (!options.isScroll) {
                return;
            }

            w.on('scroll.' + pluginName, $.throttle(function () {
                onScroll();
            }, 200)).trigger('scroll.' + pluginName);
        }

        init();
    }

    $.fn[pluginName] = function (method) {
        var args = arguments;

        return this.each(function () {
            var element = $(this);
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName, new DynamicPageContent(element, args));
            } else if (typeof method === 'string' && typeof element.data(pluginName)[method] !== 'undefined') {
                element.data(pluginName)[method].apply(element.data(pluginName), Array.prototype.slice.call(args, 1));
            }
        })
    }
})
(jQuery);