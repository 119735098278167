'use strict';
Dafi.Controller.App = {
    beforeInit: function () {
        Dafi.View.Body.init();
        Dafi.View.pjaxContainer.init();
        Dafi.View.ChangeFontSize.init();
        Dafi.View.ChangeContrast.init();
        Dafi.View.LazyLoad.init();
        Dafi.View.Map.init();
        Dafi.View.Map.initCollapse();
        Dafi.View.MediaItems.init();
        Dafi.View.MenuBlackout.init();
        Dafi.View.MenuToggle.init();
        Dafi.View.Menu.init();
        Dafi.View.PagePreloader.init();
        Dafi.View.Select.init();
        Dafi.View.ScrollUp.init();
        Dafi.View.SubscribeLine.init();
    },
    init: function () {
        this.beforeInit();

        //Is it touch device?
        if (Dafi.Helper.User.isTouchScreen()) {
            Dafi.View.Body.$el.addClass('touchscreen-yes');
        }
        else {
            Dafi.View.Body.$el.addClass('touchscreen-no');
        }
    }
};

//Init application
Dafi.Controller.App.init();