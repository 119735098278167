(function ($) {
    var pluginName = 'msg$';

    function _(element, options) {
        var self = this;

        this.element = element;

        this.options = {pos: 'b', delay: 3000};

        var n = options.length, msg;
        var placement = {t: 'top', r: 'right', b: 'bottom', l: 'left', auto: 'auto'};

        if (!n) {
            msg = element.data('errormessage-value-missing') || '';
        }
        else if (n == 2) {
            msg = options[0] || '';
            this.options = $.extend(this.options, options[1]);
        }
        else if (typeof options[0] == 'string') {
            msg = options[0] || '';
        }
        else if (typeof options[0] == 'object') {
            msg = element.data('errormessage-value-missing') || '';
            this.options = $.extend(this.options, options[0]);
        }

        if(parseInt(element.data('isShowing')) !== 1) {
            element
                .popover({
                    trigger: 'manual',
                    placement: placement[this.options.pos],
                    html: true
                })
                .data('isShowing', 1);

            var popover = element.attr('data-content', msg).data('bs.popover');
            popover.setContent();
            popover.show();

            var timer = setTimeout(function () {
                self.hide()
            }, this.options.delay);

            element.one('click', function () {
                self.hide()
            });

            popover.$tip.one('click', function () {
                self.hide()
            });
        }

        this.hide = function () {
            this.element.data('bs.popover').hide();
            clearTimeout(timer);
            element.data('isShowing', 0)
        }
    }

    $.fn[pluginName] = function (method) {
        var args = arguments;
        return this.each(function () {
            new _($(this), args)
        })
    }
})
(jQuery);