'use strict';
Dafi.View.MenuBlackout = {
    isInited: false,
    className: 'menu__blackout',
    init: function () {
        if ($("." + this.className).length === 0) {
            this.$el = $('<div/>', {
                class: this.className
            });

            this.$el.click(Dafi.View.MenuToggle.toggle);
            Dafi.View.pjaxContainer.$el.append(this.$el);

            this.isInited = true;
        }
    },
    toggle: function () {
        if (this.isInited) {
            this.$el.toggleClass('in');
        }
    },
    hide: function () {
        Dafi.View.MenuBlackout.$el.removeClass('in');
    }
};