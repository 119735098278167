'use strict';
Dafi.View.MediaItems = {
    isInited: false,
    init: function () {
        this.initOne();
    },
    initOne: function () {
        if (this.isInited) {
            return;
        }

        this.isInited = true;

        this.listen();
    },
    listen: function () {
        $(document).on('click', '[data-media-item]', function (event) {
            var context = $(this),
                mediaItems,
                mediaList = [],
                galleryWrapper;

            galleryWrapper = $(this).closest('[data-blueimp-gallery]');

            mediaItems = galleryWrapper.find('[data-media-item]').each(function (index, el) {
                el = $(el);

                var img = el.is('img') ? el : el.find('img');

                var options = {
                    href: img.attr('data-src-zoom'),
                    title: img.attr('data-title')
                };

                if (img.attr('data-type') === 'video') {
                    var youtubeID = options.href.match(/(?:youtube(?:-nocookie)?\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i)[1];

                    options.type = 'text/html';
                    options.youtube = youtubeID;
                    options.poster = 'https://img.youtube.com/vi/' + youtubeID + '/0.jpg';
                }
                else {
                    options.type = 'text/jpeg';
                }

                mediaList.push(options);
            });

            blueimp.Gallery(mediaList, {
                index: mediaItems.index(context)
            });

            event.preventDefault();
        });
    }
};