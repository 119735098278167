'use strict';
Dafi.View.LazyLoad = {
    isInited: false,
    selector: 'img[data-lazyload]',
    init: function () {
        $(this.selector).unveil();
        this.initOne();
    },
    initOne: function () {
        if (this.isInited) {
            return;
        }

        this.isInited = true;

        this.listenForCollapse();
    },
    listenForCollapse: function () {
        var context = this;

        $(document).on('shown.bs.collapse', '.panel-collapse', function () {
            $(this).find(context.selector).unveil();
        });
    }
};