Dafi.View.Body = {
    init: function () {
        this.$el = $('body');
    },
    changeStateOfOverflow: function () {
        this.$el.toggleClass('overflow-hidden');
    },
    resetStateOfOverflow: function () {
        this.$el.removeClass('overflow-hidden');
    }
};