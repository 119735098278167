'use strict';
Dafi.View.Menu = {
    init: function () {
        this.$el = $('[data-el="menu"]');
    },
    changeState: function () {
        Dafi.View.Menu.$el.toggleClass('in');
    },
    hide: function () {
        Dafi.View.Menu.$el.removeClass('in');
    }
};