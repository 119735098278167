'use strict';
Dafi.View.ChangeContrast = {
    isInited: false,
    selector: '[data-el="change-contrast"]',
    property: 'contrast',
    init: function () {
        this.initOne();
    },
    initOne: function () {
        if (this.isInited) {
            return;
        }

        this.isInited = true;

        if (this.isContrast()) {
            this.toggleObject();
        }

        this.listen();
    },
    listen: function () {
        var context = this;

        $(document).on('click', this.selector, function (event) {
            context.toggleObject();

            localStorage.setItem(
                context.property,
                !context.isContrast() ? 'yeap' : 'nope'
            );

            event.preventDefault();
        });
    },
    isContrast: function () {
        return localStorage.getItem(this.property) === 'yeap';
    },
    toggleObject: function () {
        Dafi.View.Body.$el.toggleClass(this.property);
    }
};