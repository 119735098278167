//16.06.2014 modal$ && $.modalmanager$
(function ($) {
    var fn$ = new function () {
        this.name = 'modal$';
    };

    function _(args) {
        var self = this;

        this.modal = null;
        this.options = $.extend({}, args[1]);

        this.init = function () {
            $.modalmanager$.appendModal(this);

            if (typeof args[0] == 'string') {
                this.modal = $(args[0]).modal(this.options);
                this.initModal()
            }
            else if (typeof args[0] == 'object') {
                $.modalmanager$.loading();
                this.sajax.apply(this, [{'args': args[0].sajax, 'options': args[1]}])
            }
        }

        this.initModal = function () {
            this.modal.on('hide', function () {
                self.hide.call(self, 1)
            });

            if (typeof this.options.events !== 'undefined') {
                var events = this.options.events;
                for (var i in events)this.modal.on(i, events[i]);
            }
        }

        this.show = function () {
            this.modal.modal('show');
            return this
        }

        this.hide = function (s) {
            s = s || 0;
            $.modalmanager$.destroyModal(this);
            if (!s)this.modal.modal('hide');
            this.modal = null
        }

        this.sajax = function (param) {
            param.args.push(function (r) {
                r = $(r);
                if (!self.modal) {
                    self.modal = r.modal(param.options);
                    self.initModal()
                }
                else {
                    if (r.hasClass('modal'))r = r.html();
                    self.modal.html(r).modal(param.options);
                }

                self.removeHold();
            });

            if (self.modal)self.hold();

            sajax.apply(null, param.args)
        }

        this.hold = function () {
            this.modal.modal('hold');
            return this
        }

        this.removeHold = function () {
            this.removeLoading();
            return this
        }

        this.loading = function () {
            this.modal.modal('loading');
            return this
        }

        this.removeLoading = function () {
            this.modal.modal('removeLoading');
            return this
        }

        this.init();
    }

    $[fn$.name] = function () {
        return new _(arguments)
    }
    $[fn$.name].Constructor = _;
})
(jQuery);

(function ($) {
    $.modalmanager$ = new function () {
        this.stack = [];

        this.appendModal = function (modal) {
            this.stack.push(modal)
        }

        this.getLastModal = function (modal) {
            var lastModal;
            for (var i = 0, n = this.stack.length; i < n; i++) {
                if (this.stack[i].modal.data('modal').isShown())lastModal = this.stack[i];
            }
            return lastModal
        }

        this.destroyModal = function (modal) {
            var index = this.getIndexOfModal(modal);
            if (typeof index !== 'undefined')this.stack.splice(index, 1);
        }

        this.getModalAt = function (index) {
            return this.stack[index];
        }

        this.getIndexOfModal = function (modal) {
            for (var i = 0, n = this.stack.length; i < n; i++) {
                if (modal === this.stack[i])return i;
            }
        }

        this.loading = function () {
            $('body').modalmanager('loading')
        }
    }
})
(jQuery);