'use strict';
window.GoogleMap = Dafi.Controller.GoogleMap = {
    settings: {
        scrollwheel: false,
        draggable: false,
        isInited: false
    },
    init: function (options) {
        var googleMap, center, context, $window;

        this.$routeOriginEl = $('[data-route="origin"]');

        context = this;

        this.options = $.extend(this.settings, options);

        this.options.coord.lat = parseFloat(options.coord.lat);
        this.options.coord.lng = parseFloat(options.coord.lng);
        this.options.zoom = parseInt(options.zoom);

        this.$container = $(options.container);
        $window = $(window);

        if (this.settings.isInited === false) {
            if (this.$container.length > 0) {
                $window.on('scroll.googleMap', function () {
                    if ($window.scrollTop() + $window.height() > context.$routeOriginEl.offset().top) {
                        $.getScript('http://maps.google.com/maps/api/js?callback=GoogleMap.apiLoaded&sensor=false&libraries=places', function () {
                            context.settings.isInited = true;
                        });
                        $window.off('load.googleMap scroll.googleMap');
                    }
                }).trigger('scroll.googleMap');
            }
        }
        else {
            this.apiLoaded();
        }
    },
    apiLoaded: function () {
        var context, googleMap, center;

        context = this;

        googleMap = new google.maps.Map(this.$container[0], {
            center: context.options.coord,
            zoom: context.options.zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            draggable: context.options.draggable,
            scrollwheel: context.options.scrollwheel
        });

        new google.maps.Marker({
            position: context.options.coord,
            map: googleMap,
            title: context.options.title,
            icon: context.options.icon
        });

        center = googleMap.getCenter();

        google.maps.event.trigger(googleMap, 'resize');

        google.maps.event.addDomListener(window, 'resize', $.throttle(function() {
            google.maps.event.trigger(googleMap, 'resize');
            googleMap.setCenter(center);
        }, 200));


        this.$container.click(function() {
            googleMap.setOptions({
                draggable: true,
                scrollwheel: context.options.scrollwheel
            });
        });

        $('body').click(function(e) {
            if (!context.$container.has($(e.target)).length) {
                googleMap.setOptions({
                    draggable: false
                });
            }
        });

        this.route(googleMap);
    },
    route: function (map) {
        var searchForm, directionsService, directionsDisplay, context, $htmlBody;

        context = this;
        $htmlBody = $('html, body');
        searchForm = $('[data-route="form"]');
        directionsService = new google.maps.DirectionsService();
        directionsDisplay = new google.maps.DirectionsRenderer();

        directionsDisplay.setMap(map);
        
        //Init autocomplete for input with the start point
        new google.maps.places.Autocomplete(this.$routeOriginEl[0]);

        //Handles the search event
        searchForm.submit(function (event) {
            directionsService.route({
                origin: context.$routeOriginEl.val(),
                destination: new google.maps.LatLng(context.options.coord.lat, context.options.coord.lng),
                travelMode: google.maps.TravelMode.DRIVING
            }, function (result, status) {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(result);

                    $htmlBody.animate({
                        scrollTop: context.$container.offset().top
                    }, 1000);
                }
            });

            event.preventDefault();
        });
    }
};