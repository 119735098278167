'use strict';
Dafi.View.MenuToggle = {
    isInited: false,
    selector: '[data-el="menu-toggle"]',
    init: function () {
        this.initOne();
    },
    initOne: function () {
        if (this.isInited) {
            return;
        }

        this.isInited = true;

        this.listen();
    },
    listen: function () {
        $(document).on('click', this.selector, this.toggle);
    },
    toggle: function () {
        Dafi.View.Menu.changeState();
        Dafi.View.Body.changeStateOfOverflow();
        Dafi.View.MenuBlackout.toggle();
    },
    hide: function () {
        Dafi.View.Menu.hide();
        Dafi.View.Body.resetStateOfOverflow();
        Dafi.View.MenuBlackout.hide();
    }
};