'use strict';
Dafi.View.ScrollUp = {
    isInited: false,
    selector: '[data-el="change-contrast"]',
    property: 'contrast',
    init: function () {
        this.initOne();
    },
    initOne: function () {
        if (this.isInited) {
            return;
        }

        this.isInited = true;

        this.$el = $('[data-el="scroll-up"]');

        this.listen();
    },
    listen: function () {
        var context = this;
        
        var $window = $(window);
        var $trigger = $('[data-el="scroll-up-trigger"]');
        var $page = $('html, body');

        var EL_ACTIVE_CLASS_NAME = 'active';
        var ANIMATION_TIME = 500;

        $window.scroll(function (event) {
            var windowHeight = $window.outerHeight();
            var documentHeight = document.body.scrollHeight;
            var windowScrollFromTop = $window.scrollTop();
            var scrollParcent = (windowScrollFromTop / (documentHeight - windowHeight)) * 100;

            if (scrollParcent > 70) {
                context.$el
                    .addClass(EL_ACTIVE_CLASS_NAME);
            }
            else {
                context.$el
                    .removeClass(EL_ACTIVE_CLASS_NAME);
            }
        });

        $trigger.click(function () {
            $page.animate({
                'scrollTop': 0
            }, ANIMATION_TIME);
        });
    }
};