'use strict';
Dafi.Helper.User = {
    /**
     * This is touch device?
     * @return {Boolean}
     */
    isTouchScreen: function () {
        try {  
            document.createEvent('TouchEvent');
            if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
                return true;
            }
            else {
                return false;
            }
        } catch (e) {  
            return false;
        }
    },
    /**
     * Test local storage support in browser
     * @return {boolean} support
     */
    supportLocalStorage: function () {
        var value = 'DAFI_test';

        try {
            localStorage.setItem(value, value);
            localStorage.removeItem(value);
            return true;
        } catch(e) {
            return false;
        }
    }
};