$.pjax.container = $.pjax.defaults.container = '[data-pjax-container]';
$.pjax.defaults.timeout = 3000;

$(function() {
    $(document).on('submit', 'form[data-name]', function (e) {
        e.preventDefault();
        validateForm.call(this, e);
    });

    $(document).on('click', ':button[data-captcha="btn"]', function(e) {
        e.preventDefault();
        update_captcha();
    });

    $(document).on('click', ':button[data-event="dynamic-page-content"]', function (e) {
        var self = $(e.target), target = self.data('target');

        self.attr('disabled', 'disabled');
        $(target).dynamicPageContent('load', function (response) {
            if (response === null || !response.isMore) {
                $(self.data('control-parent')).hide();
            }

            if (response.items.length) {
                self.removeAttr('disabled');
            }
        });

    });

    initShareByContext($('.product'), $('[data-gallery-share]'), {
        name: function (context) {
            return context.find('[data-share-item-name]').text()
        },
        link: function (context) {
            return location.href
        },
        description: function (context) {
            return context.find('[data-share-item-description]').text().substr(0, 150)
        },
        image: function (context) {
            return context.find('[data-share-item-image]').text()
        }
    });

    $(document).on('change', 'select[data-group="mall"]', function () {
        $.pjax({url: this.value});
    });

    $(document).on('keypress', '#mall-search-query', function (e) {
        if (e.which == 13) {
            var link = $(this).data('link'), hasParameters = link.indexOf('?') !== -1;

            if (this.value.length) {
                link += (hasParameters ? '&' : '?') + 'search=' + this.value;
            }

            $.pjax({url: link});
        }
    });

    $.customValidity$();

    $(document)
        .pjax('a:not([data-external]), a:not([hreflang]), a:not([href^="tel|mailto"])', $.pjax.container)
        .on('pjax:send', function () {
            Dafi.View.PagePreloader.show();
        })
        .on('pjax:end', function () {
            Dafi.Controller.App.init();
            Dafi.View.PagePreloader.hide();
            Dafi.View.Body.resetStateOfOverflow();
        });

    //File input replacement
    $(document).on('change', '.btn-file :file', function () {
        var input = $(this),
            numFiles = input.get(0).files ? input.get(0).files.length : 1,
            fileName = input.val().replace(/\\/g, '/').replace(/.*\//, '');
        
        input.trigger('fileselect', [numFiles, fileName]);
    });

    $(document).on('fileselect', '.btn-file :file', function (event, numFiles, fileName) {
        var fileLabel = $(this).siblings('[data-file="label"]');

        if (numFiles === 1) {
            fileLabel.text(fileLabel.data('message-single') + ' ' + fileName);
        }
        else if (numFiles > 1) {
            fileLabel.text(fileLabel.data('message-multiple') + ' ' + numFiles);
        }
        else {
            fileLabel.html(fileLabel.data('message-original') + ' ' + '<span class="label-icon">'+ fileLabel.data('message-original-icon') +'</span>');
        }
    });

    $(document).on('reset', 'form', function (event) {
        $(this)
            .find('.btn-file :file')
            .trigger('fileselect', [0, null]);
    });

    //If this is not a touch device let users click on the dropdowns toogles in the main menu
    if (!('ontouchstart' in document)) {
        $(document).on('click', '.navbar-default [data-toggle="dropdown"]', function (event) {
            if (window.innerWidth >= 1200) {
                $.pjax({
                    url: this.href
                });
                
                event.stopPropagation();
            }
        });
    }
});


function sajax() {
    var a = sajax.arguments, n = a.length, m = n - 1, d = [], f = a[m];

    if (typeof a[m] != 'function') {
        m = n;
        f = null
    }

    for (var i = 0; i < m; i++) {
        d.push('args[]=' + encodeURIComponent(typeof a[i] === 'string' ? a[i] : JSON.stringify(a[i])));
    }

    $.ajax({
        url: 'index.php',
        type: 'POST',
        data: d.join('&'),
        dataType: 'html',
        success: function (r) {
            if (typeof f == 'function') {
                f(r, false)
            }
        }
    })
}


function validateForm(event) {
    var form = this, name = $(form).data('name'), el = form.elements, is_sf = 1, fn = null, status = 1;

    $(':input[required]', form).each(function () {
        var z = $(this);
        if (z.val().trim() == '') {
            z.msg$();
            status = 0;
            return false
        }
    });

    if (!status) {
        return;
    }

    switch (name) {
        case 'request_rent':
        case 'request_event':
            fn = function () {
                form.reset();
            };
            break;
        case 'contact':
        case 'request':
            var modal = $.modalmanager$.getLastModal();
            if (modal) {
                modal.hold()
            }

            fn = function () {
                form.reset();
                update_captcha();

                if (modal) {
                    modal.hide()
                }
            }
            break;
        case 'subscribe':
        case 'unsubscribe':
            var modal = $.modalmanager$.getLastModal();
            if (modal) {
                modal.hold()
            }

            fn = function () {
                form.reset();
                if (modal) {
                    modal.hide()
                }
            }
            break;
        case 'search':
            if (!el['text'].value.match($(el['text']).attr('pattern'))) {
                $(el['text']).msg$({pos: $(form).data('pos') || 'b'});
                return 0;
            }
            $.pjax.submit(event, $.pjax.container);
            return;
    }

    if (!status) {
        return;
    }

    if (is_sf) {
        $(form).sf$({
            explode: 1,
            complete: fn
        });
    } else {
        form.submit()
    }
}


function check_captcha(e, fn) {
    var wnd = $.modalmanager$.getLastModal();
    if (wnd) {
        wnd.hold()
    }

    sajax('home', 'x_captcha', $(e).val(), function (r) {
        if (wnd) {
            wnd.removeHold();
        }

        r = parseInt(r);
        if (r) {
            e.setCustomValidity('');
            $(e).data('status', 1);
            fn()
        }
        else {
            e.setCustomValidity('!');
            $(e).closest('form').find(':button[type="submit"]').click();
            update_captcha()
        }
    })
}


function update_captcha() {
    $('img[data-captcha="img"]').attr('src', '/captcha.php?rnd=' + Math.round(Math.random() * (+new Date)))
}


function explode$(s, str, k) {
    var r = [];
    k = typeof k == 'undefined' ? 0 : k;

    if (!k){
        r = str.toString().split(s);
    } else {
        var x = str.toString().split(s),
            a = x.splice(0, k - 1),
            b = x.join(s);
        a.push(b);
        r = a
    }

    for (var i in r){
        r[i] = $.trim(r[i])
    }

    return r
}


function initShareByContext(context, container, callbacks) {
    var data = {};

    ['name', 'link', 'description', 'image'].forEach(function (attr) {
        data[attr] = callbacks[attr](context)
    });

    container.find('[data-share-btn="fb"]').on('click', function (e) {
        e.preventDefault();

        var args = [];
        args.push('u=' + encodeURIComponent(data.link));
        args.push('t=' + encodeURIComponent(data.description));

        window.open('https://www.facebook.com/sharer.php?' + args.join('&'), '_blank', 'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=550,height=440,toolbar=0,status=0')
    });

    container.find('[data-share-btn="tw"]').on('click', function (e) {
        e.preventDefault();

        var args = [];
        args.push('url=' + encodeURIComponent(data.link));
        args.push('text=' + encodeURIComponent(data.description));

        window.open('http://twitter.com/share?' + args.join('&'), '_blank', 'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=550,height=440,toolbar=0,status=0')
    });

    container.find('[data-share-btn="gp"]').on('click', function (e) {
        e.preventDefault();

        var args = [];
        args.push('url=' + encodeURIComponent(data.link));
        args.push('text=' + encodeURIComponent(data.description));

        window.open('https://plus.google.com/share?' + args.join('&'), '_blank', 'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=550,height=440,toolbar=0,status=0')
    });

    container.find('[data-share-btn="pi"]').on('click', function (e) {
        e.preventDefault();

        var args = [];
        args.push('url=' + encodeURIComponent(data.link));
        args.push('media=' + encodeURIComponent(data.image));
        args.push('description=' + encodeURIComponent(data.description));

        window.open('http://pinterest.com/pin/create/link/?' + args.join('&'), '_blank', 'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=550,height=440,toolbar=0,status=0')
    });

    container.find('[data-share-btn="tu"]').on('click', function (e) {
        e.preventDefault();

        var args = [];
        args.push('url=' + encodeURIComponent(data.link));
        args.push('name=' + encodeURIComponent(data.name));
        args.push('description=' + encodeURIComponent(data.description));

        window.open('http://www.tumblr.com/share/link?' + args.join('&'), '_blank', 'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=550,height=440,toolbar=0,status=0')
    });

    container.find('[data-share-btn="in"]').on('click', function (e) {
        e.preventDefault();

        var args = [];
        args.push('url=' + encodeURIComponent(data.link));
        args.push('name=' + encodeURIComponent(data.name));
        args.push('summary=' + encodeURIComponent(data.description));

        window.open('https://www.linkedin.com/shareArticle?mini=true&' + args.join('&'), '_blank', 'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=550,height=440,toolbar=0,status=0')
    });

    container.find('[data-share-btn="vk"]').on('click', function (e) {
        e.preventDefault();

        var args = [];
        args.push('url=' + encodeURIComponent(data.link));
        args.push('title=' + encodeURIComponent(data.name));
        args.push('image=' + encodeURIComponent(data.image));
        args.push('noparse=false');

        window.open('http://vkontakte.ru/share.php?' + args.join('&'), '_blank', 'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=650,height=550,toolbar=0,status=0')
    });
}


/*function modalServicePostRequestForm(event) {
    var post_id = $(event.target).data('id');

    $.modal$({sajax: ['service_post_request', 'requestModal', post_id]}, {
        events: {
            shown: function () {
                $.customValidity$($('form', this));
            }
        }
    })
}*/

var listeners = {};
function addEvents(name, callback) {
    if (typeof listeners[name] == 'undefined') {
        listeners[name] = [];
    }

    listeners[name].push(callback);
}

function removeEvents(name) {
    listeners[name] = [];
}

function dispatchEvents(name) {
    if (typeof listeners[name] == 'undefined') {
        return;
    }

    listeners[name].forEach(function (callback) {
        callback();
    });
}


function initDynamicPageContent(options) {
    $('[data-dynamic-page-content]').dynamicPageContent($.extend({
        bottomHeightRatio: .5,
        isScroll: false
    }, options));
}

var cacheMap = {};
function loadMap(id_floor, callback) {
    if (typeof cacheMap[id_floor] == 'undefined') {
        sajax('mall_map', 'getFloorMap', id_floor, function (r) {
            cacheMap[id_floor] = r;
            callback(r);
        });
    } else {
        callback(cacheMap[id_floor]);
    }
}

function loadFloorMap(id_floor) {
    var selector = '[data-floor-map]';
    Dafi.View.MapPreloader.appendTo(selector);

    loadMap(id_floor, function (r) {
        $(selector).html(r);
        Dafi.View.Map.init();
        dispatchEvents('loadFloorMap');
    });
}

/**
 * Get trading object for specific map object
 *
 * @param id is id_map_object
 * @param callback
 */
function loadTradingObject(id, callback)
{
    if (typeof shopsData[id]['content'] == 'undefined') {
        sajax('mall_trading_object', 'getObject', id, function (r) {
            shopsData[id]['content'] = r;
            callback(r);
        });
    } else {
        callback(shopsData[id]['content']);
    }
}

function setActiveMapObjectsForTrading(id_trading_object) {
    var map_objects_id = getMapObjectsIdByTrading(id_trading_object);
    addEvents('loadFloorMap', function () {
        var id,
            $container = $('[data-floor-map]'),
            $svgElements = $container.find('[data-trading-object]');

        $svgElements.removeAttr('class').each(function () {
            if ((id = Dafi.View.Map.getID(this)) && $.inArray(id, map_objects_id) !== -1) {
                $(this)
                    .attr('class', 'active')
                    .attr('fill', Dafi.View.Map.options.defaultColor);
            }
        });
    });
}

function getMapObjectsIdByTrading(id_trading_object) {
    var map_objects_id = [];
    $.each(shopsData, function (id_map_object, map_object) {
        if (map_object.id_trading_object === id_trading_object) {
            map_objects_id.push(parseInt(id_map_object));
        }
    });

    return map_objects_id;
}