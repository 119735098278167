(function (e, t) {
    function n(e) {
        return function (t) {
            t.target.setCustomValidity(""), e && e(t), t.target.checkValidity()
        }
    }

    function r(e) {
        return function (n) {
            var r, i, s = n.target, o = s.validity, u = "", a, f, l = {};
            try {
                t.forEach(function (e) {
                    if ((a = e.match(/\-(\w)/)) && (f = e.replace(a[0], a[1].toUpperCase())) && o[f]) {
                        u = e;
                        throw l
                    }
                })
            } catch (l) {
            }
            u && (r = s.getAttribute("data-errormessage-" + u)) ? s.setCustomValidity(r) : (i = s.getAttribute("data-errormessage")) ? s.setCustomValidity(i) : s.setCustomValidity(s.validationMessage), e && e(n)
        }
    }

    e.customValidity$ = function (t) {
        try {
            e.makeArray(e("input,select,textarea", t || document)).forEach(function (e) {
                e.willValidate && (e.tagName.toLowerCase() === "select" ? e.onchange = n(e.onchange) : e.oninput = n(e.oninput), e.oninvalid = r(e.oninvalid))
            })
        } catch (i) {
        }
    }
})(jQuery, ["value-missing", "type-mismatch", "pattern-mismatch", "too-long", "range-underflow", "range-overflow", "step-mismatch", "custom-error"]);