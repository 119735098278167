'use strict';
Dafi.View.Map = {
    options: {
        defaultColor: '#989a9b',
        hoverColor: '#f16521',
        activeColor: '#f16521'
    },
    init: function ($container) {
        var context, $el, $shopsContainer, $shops, $svgElements, $parent, $tradingObject, tradingObjectTop, shopID, prevFill, popoverBefore, wasPopoverHidden;

        if (typeof $container === 'undefined') {
            $container = $('[data-svg-map="container"]');
        }

        //Only if there is a map
        if ($container.length) {

            context = this;
            $shopsContainer = $('[data-shops]');
            $shops = $shopsContainer.find('[data-trading-object]');
            $svgElements = $container.find('[data-trading-object]');
            //Add some popovers
            this.$popoverMain = this.popover.create('popover-main');
            this.$popoverShopInfo = this.popover.create('popover-shopinfo', 'right');

            //Listen for the 'hover' event on the svg elements
            $svgElements.mouseover(function (event) {
                $el = $(this);
                shopID = context.getID($el);

                //Prepare popover
                context.popover.show(context.$popoverMain, shopID);

                //Listen for mousemove
                $container.on('mousemove.mapPopover', function (event) {
                    context.popover.setCoordinates(context.$popoverMain, {
                        coordinates: {
                            left: event.pageX + 15,
                            top: event.pageY + 15    
                        }
                    });
                });

                //Do this only if not open information about the shop
                if ($el.attr('class') !== 'active') {
                    //Set hover fill color
                    $el.attr('fill', context.options.hoverColor);
                }
            }).mouseleave(function () {
                //Set previous fill color
                $el.attr('fill', context.options.defaultColor);
                context.popover.hide(context.$popoverMain);

                //Remove the listener
                $container.off('mousemove.mapPopover');
            }).click(function (event) {
                var objectID, shopId, attr;

                $el = $(this);
                $parent = $el.closest('[data-svg-map="container"]');
                attr = $parent.attr('data-map-click');
                shopId = context.getID($el, 'data-trading-object')

                if (!(objectID = context.getID($el))) {
                    return;
                }

                if (attr === 'scroll') {
                    //Scroll to div with information about the shop
                    $tradingObject = $shopsContainer.find('[data-trading-object="' + shopId + '"]');

                    if ($tradingObject.length) {
                        tradingObjectTop = $tradingObject.offset().top;

                        if (tradingObjectTop > 0) {
                            $('html, body').animate({
                                'scrollTop': tradingObjectTop
                            }, 1000);
                        }
                    }
                } else if (attr === 'load') {
                    //Load information about the shop

                    $svgElements.removeAttr('class');

                    $el.attr('class', 'active');
                    //Reset element fill
                    $el.attr('fill', context.options.defaultColor);

                    loadTradingObject(objectID, function (response) {
                        $shopsContainer.html(response);

                        Dafi.View.LazyLoad.init();
                        Dafi.View.MediaItems.init();
                    });
                }
            });

            $container.find('svg').mouseover(function () {
                //If the container has popover hide it on hover
                if (context.$popoverShopInfo.hasClass('active')) {
                    //Save this state
                    wasPopoverHidden = true;
                    
                    //Save current popover options
                    popoverBefore = {
                        coordinates: {
                            top: context.$popoverShopInfo.css('top'),
                            left: context.$popoverShopInfo.css('left')
                        },
                        isShopInfo: true,
                        type: context.$popoverShopInfo.hasClass('right') ? 'right' : 'left'
                    };

                    context.popover.hide(context.$popoverShopInfo, true);
                }
            }).mouseleave(function () {
                //Restore the popover to its previous position
                if (wasPopoverHidden) {
                    context.popover.show(context.$popoverShopInfo);
                    context.popover.setCoordinates(context.$popoverShopInfo, popoverBefore);

                    wasPopoverHidden = false;
                }
                else {
                    context.popover.hide(context.$popoverShopInfo);
                }
            });

            $(window).resize($.throttle(function () {
                //Fix the popover position
                context.popover.shop.call(context, $shopsContainer.find('.collapse.in').find('[data-svg-map="container"]'), context.$popoverShopInfo, true);
            }, 250));


            //Highlight elements from current category
            //Do not do this for dynamically loaded maps
            if ($container.attr('data-map-loaded') !== "true") {
                if ($shops.length) {
                    //If there is a list of shops in the page
                    //Loop through shops
                    $shops.each(function (index, element) {
                        /**
                         * jQuery element of current shop 
                         * @type {object}
                         */
                        var $currentShopElement = $(element);
                        /**
                         * Id of current shop
                         * @type {string}
                         */
                        var currentShopElementID = context.getID($currentShopElement, 'data-trading-object');

                        /**
                         * Svg element with id of current shop
                         * @type {object}
                         */
                        var $svgElement = $svgElements.filter('[data-trading-object="' + currentShopElementID +'"]');

                        if ($svgElement.length) {
                            //We found it. Highlight!
                            $svgElement
                                .attr('fill', context.options.activeColor)
                                .attr('class', 'active');
                        }
                    });
                }
            }
        }
    },
    hide: function () {
        this.popover.hide(this.$popoverMain);
        this.popover.hide(this.$popoverShopInfo);

        $('[data-shops]')
            .find('.panel-collapse')
            .has('[data-svg-map="container"]')
            .collapse('hide');
    },
    initCollapse: function () {
        var context, id, idFloor, $el, $svgEl, $shopsContainer, $mapContainer;

        context = this;

        $shopsContainer = $('[data-shops]');

        $shopsContainer.find('.panel-collapse').has('[data-svg-map="container"]').on('shown.bs.collapse', function () {
            $el = $(this);
            $mapContainer = $el.find('[data-svg-map="container"]');
            
            id = context.getID($el);
            idFloor = context.getID($el, 'data-floor-id');

            if (!$mapContainer.attr('data-map-loaded')) {
                Dafi.View.MapPreloader.appendTo($mapContainer);
            }

            loadMap(idFloor, function (svg) {
                $mapContainer
                    .attr('data-map-loaded', 'true')
                    .html(svg);

                Dafi.View.Map.init($el.find('[data-svg-map="container"]'));

                //Highlight the svg element that indicate to the current shop
                $svgEl = $el.find('#map-shop' + id);

                if ($svgEl.attr('class') !== 'active') {
                    $svgEl
                        .attr('class', 'active')
                        .attr('fill', context.options.hoverColor);
                }

                context.popover.shop.call(context, $el, context.$popoverShopInfo);
            });
        });

        $shopsContainer.find('.panel-collapse').on('hide.bs.collapse', function () {
            context.popover.hide(context.$popoverShopInfo);
        });
    },
    /**
     * Get the shop id from the DOM element
     * @param  {object} $el
     * @param {string} property default 'id'
     * @return {int}|{bool} the shop id
     */
    getID: function ($el, property) {
        var id;

        if (typeof property == 'undefined') {
            property = 'id';
        }

        id = $($el).attr(property);

        return id ? parseInt(id.match(/\d+/)[0]) : false;
    },
    popover: {
        /**
         * Create a popover
         * @param  {string} ID
         * @return {object} DOM element
         */
        create: function (ID) {
            if ($('[data-svg-map="' + ID + '"]').length < 1) {
                return $('<div/>', {
                    class: 'popover popover-shop',
                }).attr({
                    'data-svg-map': ID
                }).appendTo(Dafi.View.pjaxContainer.$el);
            }
            else {
                return $('[data-svg-map="' + ID + '"]');
            }
        },
        /**
         * @param  {object} $popover
         * @param  {int} shopID
         */
        show: function ($popover, shopID) {
            if (shopID) {
                this.setHTML($popover, shopID);
            }
            
            //Show popover only if he contains text
            if ($popover.text().length) {
                $popover.addClass('active');
            }
        },
        /**
         * @param  {object} $popover
         * @param {boolean} saveInner
         */
        hide: function ($popover, saveInner) {
            $popover
                .removeClass('active')
                .removeAttr('style')

            if (!saveInner) {
                $popover
                    .text('');
            }
        },
        /**
         * Positioning the popover on the screen
         * @param {object} $popover
         * @param {object} options
         */
        setCoordinates: function ($popover, options) {
            var left, type, popoverWidth;

            popoverWidth = $popover.outerWidth(true);
            left = options.coordinates.left;

            //The default state for the popover with information about the shop
            if (options.isShopInfo) {
                type = 'right';
            }

            //Check does a popover will fit on the screen
            if ((left + popoverWidth + 100) > window.innerWidth) {
                //No, make a popover on the right side
                left = left - popoverWidth;

                if (options.isShopInfo === true) {
                    if (typeof options.element === 'object') {
                        left = left - options.element.width;
                    }
                    type = 'left';
                }
                else {
                    //This is simple offset from the cursor
                    left = left - 15;
                }
            }

            //Set calculated coordinates
            $popover.css({
                top: options.coordinates.top,
                left: left
            });

            //Does the element state is forced to be (left/right) ?
            if (options.type) {
                type = options.type;
            }

            this.setType($popover, type);
        },
        /**
         * Render the template and insert data to the DOM element
         * @param {object} $popover
         * @param {int} shopID
         */
        setHTML: function ($popover, shopID) {
            //Add some HTML to the popover
            $popover.html(tmpl('svg-map-popover-template', shopsData[shopID]));
        },
        /**
         * Set specific class for the popover arrow
         * @param {object} $popover
         * @param {string} type arrow type
         */
        setType: function ($popover, type) {
            $popover
                .removeClass('left')
                .removeClass('right');

            if (typeof type === 'string') {
                $popover.addClass(type);    
            }
        },
        /**
         * Show the popover with information about the shop
         * @param  {object} $el
         * @param  {object} $popover
         */
        shop: function ($el, $popover, isResize) {
            var offset, id, $svgEl, svgElWidth, svgElHeight;

            if (!$popover.hasClass('active') && isResize) {
                return;
            }

            $svgEl = $el.find('.active');

            if ($svgEl.length > 0) {
                //Save data about path (or circle, etc.) with wich we working
                offset = $svgEl.offset();
                svgElWidth = $svgEl[0].getBoundingClientRect().width;
                svgElHeight = $svgEl[0].getBoundingClientRect().height;

                //The shop id
                id = this.getID($el.closest('.panel-collapse'));

                this.popover.show($popover, id);
                this.popover.setCoordinates($popover, {
                    coordinates: {
                        top: offset.top - ($popover.outerHeight() / 2) + (svgElHeight / 2),
                        left: offset.left + svgElWidth,
                    },
                    element: {
                        width: svgElWidth
                    },
                    isShopInfo: true
                });
            }
        }
    }
};