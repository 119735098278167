'use strict';
Dafi.View.Select = {
    init: function () {
        this.$el = $('[data-el="bootstrap-select"]');

        this.replaceSelect();
    },
    replaceSelect: function () {
        this.$el.selectpicker({
            template: {
                caret: '<span class="appicon appicon-arrow-down"></span>'
            }
        });
    }
};