(function ($) {
    $.fn.sf$ = function (options) {
        options = $.extend({explode: 0, complete: null, callback: null}, options || {});

        return this.each(function () {
            var name = 'sf-' + Math.random(), form = $(this), div = $('<div />').appendTo('body').hide().html('<iframe name="' + name + '"></iframe>');
            form.attr('target', name);
            var iframe = div.children('iframe'), container = form.parent();
            iframe[0].onload = function () {
                var doc = (iframe[0].contentWindow && iframe[0].contentWindow.document || iframe[0].contentDocument);
                if (doc && doc.location.href != 'about:blank') {
                    if ($.isFunction(options.complete))options.complete();
                    var f = $('body', doc);
                    if (options.callback)options.callback(f);
                    else if (options.explode) {
                        var x = explode$(':', f.html(), 2);
                        console.log(f.html())
                        switch (x[0]) {
                            case 'url':
                                location.href = x[1]
                                break;
                            case 'wndmsg':
                                $.modal$(x[1])
                                break;
                            case 'alert':
                                alert(x[1])
                                break;
                        }
                    }
                    setTimeout(function () {
                        div.remove()
                    }, 1000);
                }
            };
            form[0].submit();
            if (options.preload)preload();
        })
    }
})
(jQuery);